<template>
  <div class="flex flex-col">
    <div class="text-xl font-bold">Renungan dan Informasi terkini</div>
    <div class="flex items-end space-x-1">
      <MaterialInput
        class="w-full"
        v-model="search"
        placeholder="Cari"
      />
      <MaterialButton
        @click="submitSearch"
      >
        <img class="w-8" src="~@/assets/icons/paper-plane-right.svg" alt="paper-plane">
      </MaterialButton>
      <MaterialButton
        @click="resetFilter"
      >
        <img class="w-8" src="~@/assets/icons/arrow-u-up-left.svg" alt="back">
      </MaterialButton>
    </div>
    <ArticleCard
      v-for="article in articles"
      :key="article.id"
      :article="article"
    />
    <Pagination
      @change="changePagination"
      :item-per-page="10"
      :size="200"
    >
    </Pagination>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState, mapActions } from 'vuex'
import { homeConfig } from '@/config'
const ArticleCard = defineAsyncComponent(() => import('@/components/home/ArticleCard.vue'))
const MaterialInput = defineAsyncComponent(() => import('@/components/common/MaterialInput.vue'))
const MaterialButton = defineAsyncComponent(() => import('@/components/common/MaterialButton.vue'))
const Pagination = defineAsyncComponent(() => import('@/components/common/Pagination.vue'))
export default {
  name: 'Article',
  components: {
    ArticleCard,
    Pagination,
    MaterialInput,
    MaterialButton
  },
  data () {
    return {
      queryParams: { ...homeConfig.articleDefaultQueryParams },
      search: ''
    }
  },
  created () {
    this.fetchArticle()
  },
  computed: {
    ...mapState('article', ['articles'])
  },
  methods: {
    ...mapActions('article', ['fetch']),
    fetchArticle () {
      this.fetch({
        queryParams: this.queryParams
      })
    },
    changePagination (page) {
      this.queryParams = { ...this.queryParams, page }
      this.fetchArticle()
    },
    submitSearch () {
      this.queryParams = { ...this.queryParams, search: this.search }
      this.fetchArticle()
    },
    resetFilter () {
      this.search = ''
      this.queryParams = { ...this.queryParams, search: '' }
      this.fetchArticle()
    }
  }
}
</script>

<style scoped>

</style>
